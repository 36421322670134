import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>
    <g transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
        <path d="M 4.9863281 3 A 0.99711215 0.99711215 0 0 0 4.0058594 4.0136719 L 4.0058594 11.873047 A 0.99711215 0.99711215 0 0 0 4.5371094 12.884766 A 0.99711215 0.99711215 0 0 0 4.6269531 12.927734 A 0.99711215 0.99711215 0 0 0 4.6289062 12.927734 A 0.99711215 0.99711215 0 0 0 4.7226562 12.958984 A 0.99711215 0.99711215 0 0 0 4.78125 12.972656 A 0.99711215 0.99711215 0 0 0 4.8378906 12.984375 A 0.99711215 0.99711215 0 0 0 4.9375 12.996094 A 0.99711215 0.99711215 0 0 0 5.0507812 12.998047 L 7.0039062 12.998047 A 0.99711215 0.99711215 0 0 0 7.1894531 12.982422 C 9.8501173 12.81753 12.003906 10.668421 12.003906 8.0117188 C 12.003906 5.3629956 9.8636984 3.2218486 7.2148438 3.0449219 A 0.99711215 0.99711215 0 0 0 7.0195312 3.0273438 L 5.2285156 3.0273438 A 0.99711215 0.99711215 0 0 0 4.9863281 3 z M 6 5.0214844 L 6.8632812 5.0214844 C 8.6424788 5.0214844 10.009766 6.3571187 10.009766 8.0117188 C 10.009766 9.6654188 8.6423635 11.003906 6.8632812 11.003906 L 6 11.003906 L 6 5.0214844 z"
        />
        <polygon
          id="Shape"
          stroke="currentColor"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
