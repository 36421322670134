import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)" fill="#000">
        {/* <path d="M1430 12789 c-716 -43 -1091 -228 -1281 -633 -65 -139 -102 -290
-131 -541 -10 -88 -13 -1105 -13 -5200 0 -4786 1 -5099 18 -5234 50 -413 151
-657 351 -848 141 -136 320 -228 543 -279 216 -49 231 -49 2188 -47 1814 2
2113 7 2441 38 1066 102 2120 511 3004 1168 1188 882 2049 2178 2415 3635 242
963 257 1971 45 2929 -317 1430 -1143 2760 -2286 3683 -699 565 -1548 982
-2389 1174 -444 101 -850 143 -1455 151 -654 9 -3322 11 -3450 4z m3779 -4193
c349 -52 611 -169 931 -416 546 -422 855 -1120 827 -1865 -20 -516 -190 -991
-484 -1355 -178 -221 -496 -468 -778 -605 -325 -158 -573 -190 -1318 -168
l-227 6 0 2208 0 2208 158 4 c319 8 783 -1 891 -17z"/> */}
     

      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
